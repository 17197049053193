import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/OSM";
import FullScreen from "ol/control/FullScreen";
import OverviewMap from "ol/control/OverviewMap";
import ScaleLine from "ol/control/ScaleLine";
import Rotate from "ol/control/Rotate";
import DragRotate from "ol/interaction/DragRotate";
import { altKeyOnly } from "ol/events/condition";

const urlTemplate =
  "https://openlayers.nexismundi.com/tiles/mercator/zoom-{z}/tile-y{y}-x{x}.png";

// API Documentation: https://openlayers.org/en/latest/apidoc/
const map = new Map({
  target: "map",

  controls: [
    // Button to go into full screen mode
    new FullScreen(),

    // Scale legend (in miles)
    new ScaleLine({
      bar: true,
      minWidth: 250,
      units: "imperial",
    }),

    // Show north and click to reset rotation
    new Rotate(),

    // Map preview in the corner
    new OverviewMap({
      className: "ol-overviewmap",
      collapsed: false,
      layers: [
        new TileLayer({
          source: new XYZ({
            maxZoom: 7,
            url: urlTemplate,
          }),
        }),
      ],
    }),
  ],

  layers: [
    // Main map tiles source
    new TileLayer({
      source: new XYZ({
        maxZoom: 7,
        url: urlTemplate,
        params: {
          TILED: true,
        },
      }),
    }),
  ],

  // View port settings
  view: new View({
    center: [0, 0],
    zoom: 0,
  }),
});

// Adding the ability to rotate by holding down ALT+dragging
map.interactions.push(
  new DragRotate({
    condition: altKeyOnly,
  })
);
